import axios from 'axios'
import router from '../router'
import store from '../store/index'
import {Message} from 'element-ui'

axios.defaults.baseURL = '/api'

const tip = (message, type = 'warning') => {
  Message({
    message: message,
    type: type
  })
};

const goHome = () => {
  router.replace({path: '/'});
};

const toLogin = () => {
  localStorage.clear();
  router.replace({path: '/login'})
};

// 创建axios实例
let instance = axios.create({
  timeout: 1000 * 12
});

instance.interceptors.request.use(
  config => {
    const token = store.state.token;
    token && (config.headers.Authorization = 'Bearer ' + token);
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  // 请求失败
  error => {
    const {response} = error;
    if (response) {
      switch (response.status) {
        case 401:
          toLogin();
          break;
        case 403:
          tip('您暂无权限访问！')
          toLogin();
          break;
        case 404:
          tip('请求的资源不存在');
          setTimeout(() => {
            goHome()
          }, 1000);
          break;
        default:
          break;
      }
      return Promise.reject(response)
    } else {
      tip('网络异常，请稍后重试！')
      return Promise.reject(error)
    }
  }
);

export default instance
