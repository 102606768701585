export const USER_KEY = 'user';
export const TOKEN_KEY = 'token';
export const ACCOUNT_KEY = 'accounts'
export const CUR_ACCOUNT_ID_KEY = 'currentAccountId';
export const DATE_RANGE_KEY = 'dateRange';
export const ANALYSIS_TYPE = 'analysisType';
export const ALL_ACCOUNT_KEY = 'allAccounts';
export const MAX_VARIETIES_KEY = 'maxVarieties';

export const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
        localStorage.setItem(TOKEN_KEY, token);
    },
    SET_USER(state, user) {
        state.user = user;
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    },
    REMOVE_USER(state) {
        state.user = [];
        localStorage.removeItem(USER_KEY)
    },
    SET_ACCOUNTS(state, accounts){
        state.accounts = accounts;
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(accounts));
    },
    SET_ALL_ACCOUNTS(state, allAccounts){
        state.allAccounts = allAccounts;
        localStorage.setItem(ALL_ACCOUNT_KEY, JSON.stringify(allAccounts));
    },
    ADD_ACCOUNT(state, account){
        state.accounts.push(account);
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(state.accounts))
    },
    SET_CUR_ACCOUNT_ID(state, accountId) {
        state.curAccountId = accountId;
        localStorage.setItem(CUR_ACCOUNT_ID_KEY, JSON.stringify(accountId));
    },
    SET_DATE_RANGE(state, dateRange){
        state.dateRange = dateRange;
        localStorage.setItem(DATE_RANGE_KEY, JSON.stringify(dateRange));
    },
    SET_ANALYSIS_TYPE(state, analysisType){
        state.analysisType = analysisType;
        localStorage.setItem(ANALYSIS_TYPE, analysisType);
    },
    SET_ALL_VARIETIES(state, allVarieties){
        state.allVarieties = allVarieties;
    },
    SET_GROUP_VARIETIES(state, groupVarieties){
        state.groupVarieties = groupVarieties;
    },
    SET_CHECK_ALL(state, checkAll){
        state.checkAll = checkAll;
    },
    SET_IS_COMBINE(state, isCombine){
        state.isCombine = isCombine;
    },
    SET_MAX_VARIETIES(state, maxVarieties){
        state.maxVarieties = maxVarieties;
        localStorage.setItem(MAX_VARIETIES_KEY, JSON.stringify(maxVarieties));
    },
    SET_SHOW_FOOTER(state, showFooter){
        state.showFooter = showFooter;
    }
};
