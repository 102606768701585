import axios from '../utils/http'

// 获取图表数据
export const apiAnalysisData=(api,params)=>{
  const time = new Date().getTime()
  return axios.get('/data/' + api, {params: {...params, time}})
}

// 获取交易品种
export const apiTradeVariety=(params)=>{
  const time = new Date().getTime()
  return axios.get('/data/tradeVariety', {params: {...params, time}})
}

// 获取账户概览
export const apiGeneral=()=>{
  const time = new Date().getTime()
  return axios.get('/data/general', {params: time})
}
