import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "@babel/polyfill"
import 'default-passive-events'
import moment from 'moment'

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment
Vue.prototype.goBack = function () {
    if (window.history.length > 1) {
        this.$router.back()
    } else {
        this.$router.replace('/')
    }
}
router.beforeEach((to, from, next) => {
    if (!store.getters.isLoggedIn && to.path !== '/login') {
        next({ path: '/login' })
    }
    store.commit('SET_SHOW_FOOTER', to.path !== '/')
    next()
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

