import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/LoginTemp.vue')
  // },
  {
    path: '/general',
    name: 'General',
    component: () => import(/* webpackChunkName: "general" */ '../views/General.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/replay',
    name: 'Replay',
    component: () => import(/* webpackChunkName: "replay" */ '../views/Replay.vue')
  },
  // {
  //   path: '/kline',
  //   name: 'kline',
  //   meta: {
  //     title: 'k线复盘'
  //   },
  //   component: () => import(/* webpackChunkName: "Kline" */ '../views/Kline.vue')
  // },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import(/* webpackChunkName: "analysis" */ '../views/Analysis.vue'),
    children: [
      {
        path: 'report',
        name: 'report',
        meta: {
          title: '统计报表'
        },
        component: () => import(/* webpackChunkName: "Report" */ '../views/analysis/Report.vue')
      },
      {
        path: 'netValue',
        name: 'netValue',
        meta: {
          title: '累计净值'
        },
        component: () => import(/* webpackChunkName: "NetValue" */ '../views/analysis/NetValue.vue')
      },
      {
        path: 'sharp',
        name: 'sharp',
        meta: {
          title: '夏普比率'
        },
        component: () => import(/* webpackChunkName: "Sharp" */ '../views/analysis/Sharp.vue')
      },
      {
        path: 'profitCurve',
        name: 'profitCurve',
        meta: {
          title: '盈亏曲线'
        },
        component: () => import(/* webpackChunkName: "ProfitCurve" */ '../views/analysis/ProfitCurve.vue')
      },
      {
        path: 'dailySett',
        name: 'dailySett',
        meta: {
          title: '逐日结算'
        },
        component: () => import(/* webpackChunkName: "DailySett" */ '../views/analysis/DailySett.vue')
      },
      {
        path: 'yieldDist',
        name: 'yieldDist',
        meta: {
          title: '收益率分布'
        },
        component: () => import(/* webpackChunkName: "YieldDist" */ '../views/analysis/YieldDist.vue')
      },
      {
        path: 'returnRatio',
        name: 'returnRatio',
        meta: {
          title: '收益回撤比'
        },
        component: () => import(/* webpackChunkName: "ReturnRatio" */ '../views/analysis/ReturnRatio.vue')
      }, {
        path: 'weekProfit',
        name: 'weekProfit',
        meta: {
          title: '每周盈亏'
        },
        component: () => import(/* webpackChunkName: "WeekProfit" */ '../views/analysis/WeekProfit.vue')
      }, {
        path: 'monthProfit',
        name: 'monthProfit',
        meta: {
          title: '每月盈亏'
        },
        component: () => import(/* webpackChunkName: "MonthProfit" */ '../views/analysis/MonthProfit.vue')
      }, {
        path: 'topTen',
        name: 'topTen',
        meta: {
          title: '盈亏TOP10'
        },
        component: () => import(/* webpackChunkName: "TopTen" */ '../views/analysis/TopTen.vue')
      }, {
        path: 'takeCycle',
        name: 'takeCycle',
        meta: {
          title: '持仓周期'
        },
        component: () => import(/* webpackChunkName: "TakeCycle" */ '../views/analysis/TakeCycle.vue')
      }, {
        path: 'cycleProfit',
        name: 'cycleProfit',
        meta: {
          title: '周期盈亏'
        },
        component: () => import(/* webpackChunkName: "CycleProfit" */ '../views/analysis/CycleProfit.vue')
      }, {
        path: 'cycleOdds',
        name: 'cycleOdds',
        meta: {
          title: '周期胜率'
        },
        component: () => import(/* webpackChunkName: "CycleOdds" */ '../views/analysis/CycleOdds.vue')
      }, {
        path: 'tradeStruct',
        name: 'tradeStruct',
        meta: {
          title: '成交结构'
        },
        component: () => import(/* webpackChunkName: "TradeStruct" */ '../views/analysis/TradeStruct.vue')
      }, {
        path: 'varietyProfit',
        name: 'varietyProfit',
        meta: {
          title: '品种盈亏'
        },
        component: () => import(/* webpackChunkName: "VarietyProfit" */ '../views/analysis/VarietyProfit.vue')
      }, {
        path: 'varietyOdds',
        name: 'varietyOdds',
        meta: {
          title: '品种胜率'
        },
        component: () => import(/* webpackChunkName: "VarietyOdds" */ '../views/analysis/VarietyOdds.vue')
      }, {
        path: 'longShort',
        name: 'longShort',
        meta: {
          title: '多空仓位'
        },
        component: () => import(/* webpackChunkName: "LongShort" */ '../views/analysis/LongShort.vue')
      }, {
        path: 'overnight',
        name: 'overnight',
        meta: {
          title: '隔夜仓位'
        },
        component: () => import(/* webpackChunkName: "Overnight" */ '../views/analysis/Overnight.vue')
      }, {
        path: 'dispersion',
        name: 'dispersion',
        meta: {
          title: '交分散度'
        },
        component: () => import(/* webpackChunkName: "Dispersion" */ '../views/analysis/Dispersion.vue')
      }, {
        path: 'longProfit',
        name: 'longProfit',
        meta: {
          title: '多单盈亏'
        },
        component: () => import(/* webpackChunkName: "LongProfit" */ '../views/analysis/LongProfit.vue')
      }, {
        path: 'shortProfit',
        name: 'shortProfit',
        meta: {
          title: '空单盈亏'
        },
        component: () => import(/* webpackChunkName: "ShortProfit" */ '../views/analysis/ShortProfit.vue')
      }, {
        path: 'takeTotal',
        name: 'takeTotal',
        meta: {
          title: '持仓汇总'
        },
        component: () => import(/* webpackChunkName: "TakeTotal" */ '../views/analysis/TakeTotal.vue')
      }, {
        path: 'takeDetail',
        name: 'takeDetail',
        meta: {
          title: '持仓明细'
        },
        component: () => import(/* webpackChunkName: "TakeDetail" */ '../views/analysis/TakeDetail.vue')
      },
      {
        path: 'exposure',
        name: 'exposure',
        meta: {
          title: '单边敞口'
        },
        component: () => import(/* webpackChunkName: "Exposure" */ '../views/analysis/Exposure.vue')
      },
      {
        path: 'tradeDetail',
        name: 'tradeDetail',
        meta: {
          title: '成交明细'
        },
        component: () => import(/* webpackChunkName: "TradeDetail" */ '../views/analysis/TradeDetail.vue')
      },
      {
        path: 'singleVariety',
        name: 'singleVariety',
        meta: {
          title: '单品种-盈亏曲线'
        },
        component: () => import(/* webpackChunkName: "SingleVariety" */ '../views/analysis/SingleVariety.vue')
      },
      {
        path: 'equities',
        name: 'equities',
        meta: {
          title: '历史权益'
        },
        component: () => import(/* webpackChunkName: "Equities" */ '../views/analysis/Equities.vue')
      },
      {
        path: 'varietyWeekProfit',
        name: 'varietyWeekProfit',
        meta: {
          title: '单品种-每周盈亏'
        },
        component: () => import(/* webpackChunkName: "VarietyWeekProfit" */ '../views/analysis/VarietyWeekProfit.vue')
      },
      {
        path: 'varietyMonthProfit',
        name: 'varietyMonthProfit',
        meta: {
          title: '单品种-每月盈亏'
        },
        component: () => import(/* webpackChunkName: "varietyMonthProfit" */ '../views/analysis/VarietyMonthProfit.vue')
      },
      {
        path: 'kline',
        name: 'kline',
        meta: {
          title: 'k线复盘'
        },
        component: () => import(/* webpackChunkName: "Kline" */ '../views/analysis/Kline.vue')
      },
    ]
  }
]

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
