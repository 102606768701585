import Vue from 'vue'
import Vuex from 'vuex'
import tagsView from './tagsView'
import {mutations, USER_KEY, ACCOUNT_KEY, CUR_ACCOUNT_ID_KEY, DATE_RANGE_KEY, ANALYSIS_TYPE, TOKEN_KEY, ALL_ACCOUNT_KEY, MAX_VARIETIES_KEY} from './mutations'
import {apiTradeVariety} from "../api/analysis";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: localStorage.getItem(TOKEN_KEY) || '',
        user: JSON.parse(localStorage.getItem(USER_KEY) || '[]'),
        accounts: JSON.parse(localStorage.getItem(ACCOUNT_KEY) || '[]'),
        allAccounts: JSON.parse(localStorage.getItem(ALL_ACCOUNT_KEY) || '[]'),
        curAccountId: JSON.parse(localStorage.getItem(CUR_ACCOUNT_ID_KEY)) || [],
        analysisType: localStorage.getItem(ANALYSIS_TYPE) || 'single',
        dateRange: JSON.parse(localStorage.getItem(DATE_RANGE_KEY)) || {label: '全周期', b_date: '', e_date: ''},
        indexList: {
            agricultural: '农副组',
            metal: '金属组',
            energy: '能化组',
            black: '黑色系',
            financial: '金融组',
            other: '其他'
        },
        isCombine: false,
        allVarieties: [],
        groupVarieties: [],
        checkAll: false,
        maxVarieties: JSON.parse(localStorage.getItem(MAX_VARIETIES_KEY)) || [],
        showFooter: true
    },
    mutations,
    actions: {
        REMOVE_ACCOUNT (context, accountId) {
            accountId = parseInt(accountId);
            let accounts = context.state.accounts;
            for(let index in accounts){
                if(accounts[index].id === accountId){
                    accounts.splice(index, 1);
                    context.commit('SET_ACCOUNTS', accounts);
                }
            }
        },
        async setAllVarieties (context) {
            const params = {
                a_id: context.state.curAccountId.join(','),
                type: context.state.analysisType,
                b_date: context.state.b_date,
                e_date: context.state.e_date
            }
            let res = await apiTradeVariety(params)
            if (res.data.code === 0 && res.data.data) {
                const resData = JSON.parse(JSON.stringify(res.data.data))
                let groupVarieties = []
                let groupKeys = []
                resData.forEach((item, index) => {
                    if (context.state.checkAll) {
                        item.isChecked = true
                    } else {
                        index === 0 ? item.isChecked = true : item.isChecked = false
                    }
                    if (!groupKeys.includes(item.group)) {
                        groupKeys.push(item.group)
                        groupVarieties.push({
                            label: context.state.indexList[item.group],
                            key: item.group,
                            isChecked: context.state.checkAll,
                            indeterminate: index === 0 ? true : context.state.checkAll
                        })
                    }
                })
                context.commit('SET_ALL_VARIETIES', resData);
                context.commit('SET_GROUP_VARIETIES', groupVarieties);
            }
        },
        groupCheck(context, index) {
            const group = context.state.groupVarieties[index]
            context.state.checkAll = context.state.groupVarieties.every(item => item.isChecked)
            let res = context.state.allVarieties.map(item => {
                if (item.group === group.key) {
                    item.isChecked = group.isChecked
                }
                return item
            })
            if (context.state.groupVarieties.every(item => !item.isChecked) && context.getters.checkedVarieties.join(',') === '') {
                const leftIndex = res.findIndex(item => item.group === group.key)
                res[leftIndex].isChecked = true
                group.indeterminate = true
            }
            context.commit('SET_ALL_VARIETIES', res);
        },
        async getMaxVarieties (context) {
            if (context.state.user && context.state.accounts.length > 0) {
                let accounts = []
                context.state.accounts.map(item => {
                    accounts.push(item.id)
                })
                const params = {
                    a_id: accounts.join(','),
                    type: accounts.length===1?'single':'multiple'
                }
                let res = await apiTradeVariety(params)
                if (res.data.code === 0 && res.data.data) {
                    context.commit('SET_MAX_VARIETIES', res.data.data);
                }
            }
        }
    },
    modules: {
      tagsView
    },
    getters: {
        isLoggedIn: state => state.token !== '',
        curAccountId: state => state.curAccountId,
        hasUserInfo: state => state.user !== null,
        checkedVarieties: state => state.allVarieties.filter(item => item.isChecked).map(item => item.code)
    }
})
