<template>
    <div id="app">
        <router-view class="content"/>
<!--        <div class="footer" v-show="showFooter">-->
<!--            <p>© 2019 大盘手网络科技 <a class="icp" href="http://beian.miit.gov.cn">渝ICP备19015980号-1</a></p>-->
<!--        </div>-->
    </div>
</template>
<script>
    export default {
      computed: {
        showFooter () {
          return this.$store.state.showFooter
        }
      }
    }
</script>

<style lang="scss">
    html,body{
        margin: 0;
        padding: 0;
        height: 100%;
        background: #f5f6f7;
    }
    body {
        /*background-image: url("assets/bg.jpg");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        /*background-attachment: fixed;*/
    }

    .content{
        min-height: 100%;
        height: auto;
        margin: 0 auto -60px;
        box-sizing: border-box;
    }

    .clearfix:after{
        content:" ";
        display: block;
        clear: both;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            width: 1170px;
        }
    }

    .header {
        /*background: #282828;*/
        background: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
        /*opacity: 0.7;*/
        height: 60px;

        .el-menu {
            background: none;

            &.el-menu--horizontal {
                border: 0;
            }

            .el-menu-item {
                background: none;
            }
        }
    }

    #app {
        font-family: Microsoft YaHei,FreeSans,Arimo,Lucida Grande,serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        height: 100%;
    }

    .el-card{
        .el-card__header{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .footer {
        background: #FFFFFF;
        height: 60px;

        p {
            line-height: 60px;
            color: #333333;
            font-size: 14px;
            margin: 0;

            a {
                text-decoration: none;
                color: #333333;

                &.icp {
                    margin-left: 30px;
                }
            }
        }
    }
</style>
